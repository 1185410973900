<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <!--          <icon name="chart-bar" class="text-icon"></icon>-->
        </span>
        <div class="d-flex">
          <div class="box-title">
            <span class="fs-xl text mx-2"
              ><dv-decoration-11 style="width: 200px; height: 60px"
                >汇总数据</dv-decoration-11
              ></span
            >
          </div>
          <!--          <dv-decoration-3 class=" mt10" />-->
        </div>
      </div>
      <div class="d-flex jc-center">
        <!--        <CenterLeft1Chart />-->
      </div>
      <!-- 4个主要的数据 -->
      <div class="data-grid-0" v-if="this.screenType === 0">
        <div
          v-for="(item, index) in numberData1"
          :key="index"
          class="df aic jcc"
          style="flex-direction: column"
        >
          <div style="font-size: 28px; line-height: 34px">
            <countTo
              class="dv-digital-flop"
              :startVal="start"
              :endVal="item.number ? item.number : 0"
              :duration="3000"
              :decimals="item.decimals"
            ></countTo>
          </div>
          <div style="color: #c3cbde; line-height: 16px">
            {{ item.text }}
            <span
              v-if="
                item.text === '今日支出' ||
                item.text === '历史总支出' ||
                item.text === '历史总收入' ||
                item.text === '今日收入'
              "
              class="colorYellow"
              >(元)</span
            >
            <span v-else class="colorYellow">(次)</span>
          </div>
          <div style="height: 0; line-height: 20px">
            <p v-if="item.text === '历史总支出'" class="grey">
              (应付:{{ data.gzIncome }}元)
            </p>
            <p v-if="item.text === '历史总收入'" class="grey">
              (应收:{{ data.gzSpending }}元)
            </p>
            <p v-if="item.text === '历史总支出'" class="grey">
              (已付款未收货:{{ data.fkNoReceive }}元)
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="bottom-data1">
          <div
            class="item-box mt-2"
            v-for="(item, index) in numberData1"
            :key="index"
          >
            <div class="d-flex">
              <countTo
                class="dv-digital-flop"
                :startVal="start"
                :endVal="item.number ? item.number : 0"
                :duration="3000"
                :decimals="item.decimals"
              ></countTo>
            </div>
            <p
              class="text"
              :style="
                (item.text === '历史总支出' && data.gzIncome) ||
                (item.text === '历史总收入' && data.gzSpending)
                  ? 'text-align: center;height:20px'
                  : 'text-align: center;'
              "
            >
              {{ item.text }}
              <span
                v-if="
                  item.text === '今日支出' ||
                  item.text === '历史总支出' ||
                  item.text === '历史总收入' ||
                  item.text === '今日收入' ||
                  item.text === '今日采购费用'
                "
                class="colorYellow"
                >(元)</span
              >
              <span v-else class="colorYellow">(次)</span>
            </p>
            <p v-if="item.text === '历史总支出'" class="grey">
              (应付:{{ data.gzIncome }}元)
            </p>
            <p v-if="item.text === '历史总收入'" class="grey">
              (应收:{{ data.gzSpending }}元)
            </p>
          </div>
        </div>-->
      <div v-else class="df">
        <div class="data-grid">
          <div
            v-for="(item, index) in numberData2"
            :key="index"
            class="df aic jcc"
            style="flex-direction: column"
          >
            <div style="font-size: 28px; line-height: 34px">
              <countTo
                class="dv-digital-flop"
                :startVal="start"
                :endVal="item.number ? item.number : 0"
                :duration="3000"
                :decimals="item.decimals"
              ></countTo>
            </div>
            <div style="color: #c3cbde; line-height: 16px">
              {{ item.text }}
              <span
                v-if="
                  item.text === '今日支出' ||
                  item.text === '历史总支出' ||
                  item.text === '历史总收入' ||
                  item.text === '今日收入'
                "
                class="colorYellow"
                >(元)</span
              >
              <span v-else class="colorYellow">(次)</span>
            </div>
            <div style="height: 0; line-height: 20px">
              <p v-if="item.text === '历史总支出'" class="grey">
                (应付:{{ data.gzIncome }}元)
              </p>
              <p v-if="item.text === '历史总支出'" class="grey">
                (已付款未收货:{{ data.fkNoReceive }}元)
              </p>
              <p v-if="item.text === '历史总收入'" class="grey">
                (应收:{{ data.gzSpending }}元)
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="bottom-data">
          <div
            class="item-box mt-2"
            v-for="(item, index) in numberData2"
            :key="index"
          >
            <div class="d-flex">
              <countTo
                class="dv-digital-flop"
                :startVal="start"
                :endVal="item.number ? item.number : 0"
                :duration="3000"
                :decimals="item.decimals"
              ></countTo>
            </div>
            <p
              class="text"
              :style="
                (item.text === '历史总支出' && data.gzIncome) ||
                (item.text === '历史总收入' && data.gzSpending)
                  ? 'text-align: center;height:20px'
                  : 'text-align: center;'
              "
            >
              {{ item.text }}
              <span
                v-if="
                  item.text === '今日支出' ||
                  item.text === '历史总支出' ||
                  item.text === '历史总收入' ||
                  item.text === '今日收入'
                "
                class="colorYellow"
                >(元)</span
              >
              <span v-else class="colorYellow">(次)</span>
            </p>
            <p v-if="item.text === '历史总支出' && data.gzIncome" class="grey">
              (应付:{{ data.gzIncome }}元)
            </p>
            <p
              v-if="item.text === '历史总收入' && data.gzSpending"
              class="grey"
            >
              (应收:{{ data.gzSpending }}元)
            </p>
          </div>
        </div> -->
        <div v-if="!time.startTime">
          <!--                <dv-decoration-9 style="width:200px;height:200px;position: absolute;bottom: 30px;left: 0;right: 0;margin: auto"> </dv-decoration-9>-->
          <div class="d-flex jc-center">
            <CenterLeft1Chart :data="data" />
          </div>
        </div>
        <div v-else class="bottom-data">
          <h1></h1>
          <h1></h1>
          <div class="item-box mt-2">
            <div class="d-flex">
              <countTo
                class="dv-digital-flop"
                :startVal="start"
                :endVal="totalIncome ? totalIncome : 0"
                :duration="3000"
                :decimals="2"
              ></countTo>
            </div>
            <p class="text" style="text-align: center">
              时段总收入
              <span class="colorYellow">(元)</span>
            </p>
          </div>

          <div class="item-box mt-2">
            <div class="d-flex">
              <div class="dv-digital-flop">
                {{ time.startTime.split(" ")[0] }}
              </div>
            </div>
            <p class="text" style="text-align: center">
              开始时间
              <span class="colorYellow"></span>
            </p>
          </div>

          <div class="item-box mt-2">
            <div class="d-flex">
              <countTo
                class="dv-digital-flop"
                :startVal="start"
                :endVal="spendingATotal ? spendingATotal : 0"
                :duration="3000"
                :decimals="2"
              ></countTo>
            </div>
            <p class="text" style="text-align: center">
              时段总支出
              <span class="colorYellow">(元)</span>
            </p>
          </div>
          <div class="item-box mt-2">
            <div class="d-flex">
              <div class="dv-digital-flop">
                {{ time.endTime.split(" ")[0] }}
              </div>
            </div>
            <p class="text" style="text-align: center">
              结束时间
              <span class="colorYellow"></span>
            </p>
          </div>
          <!--                    <div>-->
          <!--                        {{totalIncome}}-->
          <!--                    </div>-->
          <!--                    <div>-->
          <!--                        {{spendingATotal}}-->
          <!--                    </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import CenterLeft1Chart from "../../../../components/centerLeft1Chart";
export default {
  components: {
    countTo,
    CenterLeft1Chart,
  },
  props: {
    data: {
      type: Object,
    },
    screenType: {
      type: Number,
    },
    time: {
      type: Object,
    },
    spendingATotal: {
      type: Number,
    },
    totalIncome: {
      type: Number,
    },
  },
  data() {
    return {
      numberData1: [
        {
          number: this.data.totalIncome,
          text: "历史总收入",
          decimals: 2,
        },
        {
          number: this.data.todayTotalIncome,
          text: "今日收入",
          decimals: 2,
        },
        {
          number: this.data.shipmentsCount,
          text: "全部发货次数",
          decimals: 0,
        },
        {
          number: this.data.todayShipmentsCount,
          text: "今日发货次数",
          decimals: 0,
        },

        {
          number: this.data.spendingATotal,
          text: "历史总支出",
          decimals: 2,
        },
        {
          number: this.data.todaySpendingATotal,
          text: "今日采购费用",
          decimals: 2,
        },
        {
          number: this.data.receiveCount,
          text: "全部收货次数",
          decimals: 0,
        },
        {
          number: this.data.todayReceiveCount,
          text: "今日收货次数",
          decimals: 0,
        },
      ],

      numberData2: [
        {
          number: this.data.totalIncome,
          text: "历史总收入",
          decimals: 2,
        },
        {
          number: this.data.todayTotalIncome,
          text: "今日收入",
          decimals: 2,
        },
        {
          number: this.data.spendingATotal,
          text: "历史总支出",
          decimals: 2,
        },
        {
          number: this.data.todaySpendingATotal,
          text: "今日支出",
          decimals: 2,
        },
      ],
      start: 0,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 375px;

#centerLeft1 {
  padding-top: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    width: 50%;
    .item-box {
      /*margin-top: 40px;*/
      & > div {
        padding-right: 5px;
        display: flex;
        justify-content: center;
      }
      font-size: 16px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;

      .dv-digital-flop {
        width: 150px;
        height: 30px;
        font-size: 28px;
        color: #1eb6fe;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
  .bottom-data1 {
    /*width: 100%;*/
    .item-box {
      /*margin-top: 40px;*/
      & > div {
        padding-right: 5px;
        display: flex;
        justify-content: center;
      }
      font-size: 16px;
      float: right;
      position: relative;
      width: 25%;
      color: #d3d6dd;

      .dv-digital-flop {
        width: 150px;
        height: 30px;
        font-size: 28px;
        color: #1eb6fe;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
}
.dv-decoration-3 {
  svg {
    transform: none !important;
  }
}
.df {
  display: flex;
}
.grey {
  color: #909399;
}

.data-grid {
  width: 50%;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 0px;
  grid-template-areas:
    ". ."
    ". .";
}
.data-grid-0 {
  height: 80%;
  padding-bottom: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 0px;
  grid-template-areas:
    ". . . ."
    ". . . .";
}
.jcc {
  justify-content: center;
}
.aic {
  align-items: center;
}
.colorYellow {
  color: yellowgreen;
}
</style>
